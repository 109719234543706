// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-crypto-binance-us-comm-js": () => import("./../../../src/pages/crypto/binance-us-comm.js" /* webpackChunkName: "component---src-pages-crypto-binance-us-comm-js" */),
  "component---src-pages-crypto-bitcoin-btc-10-js": () => import("./../../../src/pages/crypto/bitcoin-btc-$10.js" /* webpackChunkName: "component---src-pages-crypto-bitcoin-btc-10-js" */),
  "component---src-pages-crypto-brave-browser-js": () => import("./../../../src/pages/crypto/brave-browser.js" /* webpackChunkName: "component---src-pages-crypto-brave-browser-js" */),
  "component---src-pages-crypto-earncrypto-js": () => import("./../../../src/pages/crypto/earncrypto.js" /* webpackChunkName: "component---src-pages-crypto-earncrypto-js" */),
  "component---src-pages-crypto-eos-eos-50-js": () => import("./../../../src/pages/crypto/eos-eos-$50.js" /* webpackChunkName: "component---src-pages-crypto-eos-eos-50-js" */),
  "component---src-pages-crypto-orchid-oxt-52-js": () => import("./../../../src/pages/crypto/orchid-oxt-$52.js" /* webpackChunkName: "component---src-pages-crypto-orchid-oxt-52-js" */),
  "component---src-pages-crypto-stellar-xlm-50-js": () => import("./../../../src/pages/crypto/stellar-xlm-$50.js" /* webpackChunkName: "component---src-pages-crypto-stellar-xlm-50-js" */),
  "component---src-pages-crypto-tezos-xtz-faucet-js": () => import("./../../../src/pages/crypto/tezos-xtz-faucet.js" /* webpackChunkName: "component---src-pages-crypto-tezos-xtz-faucet-js" */),
  "component---src-pages-crypto-trustwallet-twt-125-js": () => import("./../../../src/pages/crypto/trustwallet-twt-125.js" /* webpackChunkName: "component---src-pages-crypto-trustwallet-twt-125-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

